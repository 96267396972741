<template>
  <div class="loading" v-if="LoadingStatus">
    <b-container>
      <div class="loading-pi">
        <img
          src="~@/assets/images/loader/circle.png"
          alt=""
          class="circle-bg"
        />
        <img src="~@/assets/images/loader/logo.png" alt="" class="logo-b " />
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      isLoading: true,
      fullPage: true,
    };
  },
  computed: {
    ...mapGetters({
      LoadingStatus: 'LoadingStatus',
    }),
    ...mapState({
      totalSend: 'totalSend',
      totalReceive: 'totalReceive',
    }),
  },
};
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background: rgba($color: #000000, $alpha: 0.7);
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 100%;
    .loading-pi {
      width: 175px;
      height: 175px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .circle-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        animation: circleRotate 4s linear infinite;
        z-index: 0;
      }
      .logo-b {
        height: 80%;
        width: auto;
        position: relative;
        animation: logoLight 4s linear infinite;
      }
    }
  }
  @keyframes circleRotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes logoLight {
    0% {
      filter: drop-shadow(0px 0px 0px #fff);
      z-index: 0;
    }
    50% {
      filter: drop-shadow(0px 0px 2px #fff);
      z-index: 1;
    }
    100% {
      filter: drop-shadow(0px 0px 0px #fff);
      z-index: 0;
    }
  }
}
</style>
