<script>
export default {
    data() {
        return {};
    },
    computed: {
        menuList() {
            return [
                {
                    id: 1,
                    name: 'Home',
                    label: this.$t('home'),
                },
                {
                    id: 2,
                    name: 'AboutUs',
                    label: this.$t('aboutUs'),
                    children: [
                        {
                            id: 12,
                            name: 'About',
                            label: this.$t('productsAndServices'),
                        },
                        {
                            id: 13,
                            name: 'Company',
                            label: this.$t('organization'),
                        },
                        {
                            id: 14,
                            name: 'Cooperate',
                            label: this.$t('cooperationWithPIF'),
                        },
                    ],
                },
                {
                    id: 3,
                    name: 'Products',
                    label: this.$t('productsAndServices'),
                    children: [
                        {
                            id: 10,
                            name: 'Representative',
                            label: this.$t('typicalProducts'),
                        },
                        {
                            id: 11,
                            name: 'Service',
                            label: this.$t('serviceProvided'),
                        },
                    ],
                },
                {
                    id: 5,
                    label: this.$t('project'),
                    name: 'Projects',
                    children: [
                        {
                            id: 9,
                            name: 'Investing',
                            label: this.$t('projectIsCallingForCapital'),
                        },
                    ],
                },
                {
                    id: 6,
                    name: 'News',
                    label: this.$t('news'),
                },
                {
                    id: 7,
                    name: 'Events',
                    label: this.$t('events'),
                },
                {
                    id: 8,
                    name: 'Contacts',
                    label: this.$t('contact'),
                },
                {
                    id: 15,
                    name: this.$t('downloadApp'),
                    label: 'App',
                    children: [
                        {
                            id: 16,
                            name: 'android',
                            label: 'Android',
                        },
                        {
                            id: 17,
                            name: 'ios',
                            label: 'Ios',
                        },
                    ],
                },
            ];
        },
    },
};
</script>
<template>
    <b-container fluid class="footer">
        <b-container>
            <b-row>
                <b-col cols="12">
                    <div class="box-logo">
                        <img
                            src="~@/assets/images/logo/logo-light-2.png"
                            alt=""
                        />
                    </div>
                </b-col>
                <b-col lg="7" cols="12">
                    <div class="company-info">
                        <div class="info-detail">
                            <div class="title">
                                1.{{ $t('holdingCompany') }} ENTERPRISE SERVICE MANAGEMENT
                                PTE. LTD
                            </div>
                            <div class="detail">
                                <div class="line-1 line">
                                    10 Anson Road # 13 - 09 International Plaza
                                    Singapore {{ $t('fieldWork') }}
                                </div>
                                <div class="line-2 line">
                                    {{ $t('companyNumber') }}: <span>202203414Z</span>
                                </div>
                            </div>
                        </div>
                        <div class="info-detail">
                            <div class="title">
                                {{ $t('fundJoint') }}
                            </div>
                            <div class="detail">
                                <div class="line-1 text-uppercase">
                                    <img
                                        src="~@/assets/images/mock/social/map-white.png"
                                        alt=""
                                    />
                                    {{ $t('representativeEnterpriseAddress') }}
                                </div>
                                <div class="line-1">
                                    <img
                                        src="~@/assets/images/mock/social/phone-white.png"
                                        alt=""
                                    />19000.99938
                                </div>
                                <div class="line-1">
                                    <img
                                        src="~@/assets/images/mock/social/phone-white.png"
                                        alt=""
                                    />FOR ENGLISH, PLEASE CONTACT: 0942 307 041
                                </div>
                                <div class="line-1 line">
                                    <img
                                        src="~@/assets/images/mock/social/global-white.png"
                                        alt=""
                                    />Support@pifund.io
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col lg="5" cols="12" class="p-0">
                    <b-row>
                        <b-col cols="12" md="6">
                            <div class="box-nav-footer">
                                <div class="title">{{ $t('category') }}</div>
                                <ul class="nav-footer">
                                    <li
                                        v-for="menu in menuList"
                                        :key="`footer-menu-${menu.id}`"
                                    >
                                        <router-link :to="{ name: menu.name }">
                                            {{ menu.label }}
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </b-col>
                        <b-col cols="12" md="6" class="maps-social">
                            <div class="map">
                                <img
                                    src="~@/assets/images/mock/map.png"
                                    alt=""
                                    width="100%"
                                />
                            </div>
                            <div class="social">
                                <div class="title my-3 text-uppercase">
                                    {{ $t('contact') }}
                                </div>
                                <div class="social-list">
                                    <a
                                        href="https://bit.ly/3ruFJHs"
                                        target="_blank"
                                    >
                                        <img
                                            src="~@/assets/images/mock/social/fb.png"
                                            alt=""
                                        />
                                    </a>
                                    <a
                                        href="https://bit.ly/3Gaj5YD"
                                        target="_blank"
                                    >
                                        <img
                                            src="~@/assets/images/mock/social/tg.png"
                                            alt=""
                                        />
                                    </a>
                                    <a
                                        href="https://bit.ly/3ofAUzG"
                                        target="_blank"
                                    >
                                        <img
                                            src="~@/assets/images/mock/social/tg.png"
                                            alt=""
                                        />
                                    </a>
                                    <a
                                        href="https://bit.ly/3FYmyts"
                                        target="_blank"
                                    >
                                        <img
                                            src="~@/assets/images/mock/social/tw.png"
                                            alt=""
                                        />
                                    </a>
                                    <a
                                        href="https://bit.ly/3ohzWTz"
                                        target="_blank"
                                    >
                                        <img
                                            src="~@/assets/images/mock/social/yt.png"
                                            alt=""
                                        />
                                    </a>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>
<style lang="scss">
.footer {
    background: {
        image: url(~@/assets/images/background/footer.jpg);
        size: cover;
        position: center center;
    }
    min-height: 400px;
    padding: 30px 0;
    .title {
        font-weight: 600;
        color: #fff;
        font-size: 22px;
        margin-bottom: 15px;
    }
    .box-logo {
        padding-bottom: 40px;
        margin-bottom: 40px;
        position: relative;
        width: max-content;
        &:after {
            content: '';
            position: absolute;
            height: 2px;
            width: 115%;
            background-color: #fff;
            bottom: 0;
            left: 10%;
            right: 0;
        }
    }
    .company-info {
        padding: 0 10% 0 15px;
        .info-detail {
            .detail {
                color: #fff;
                font-size: 18px;
                margin-bottom: 10px;
                .line {
                    margin-top: 10px;
                    text-transform: uppercase;
                    font-weight: 350;
                    span {
                        font-weight: 600;
                        letter-spacing: 0.8px;
                    }
                }
                .line-1 {
                    margin-top: 10px;
                    text-transform: uppercase;
                    font-weight: 350;
                    display: flex;
                    align-items: center;
                    img {
                        width: auto;
                        height: 18px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    .box-nav-footer {
        width: max-content;
        margin: auto;
        .nav-footer {
            list-style-type: none;
            padding: 0;
            li {
                margin-bottom: 5px;
                a {
                    color: #fff;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 19px;
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }
        }
    }
    .social-list {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        a {
            display: block;
            margin: 5px;
            img {
                width: 40px;
                height: auto;
            }
        }
    }
    @media (max-width: 1439px) {
        .title {
            font-size: 18px;
        }
        .company-info {
            .info-detail {
                .detail {
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
        .box-nav-footer {
            .nav-footer {
                li {
                    margin-bottom: 5px;
                    a {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    @media (max-width: 1199px) {
        .title {
            font-size: 17px;
        }
        .company-info {
            .info-detail {
                .detail {
                    font-size: 15px;
                    font-weight: 500;
                }
            }
        }
        .box-nav-footer {
            .nav-footer {
                li {
                    margin-bottom: 5px;
                    a {
                        font-size: 15px;
                    }
                }
            }
        }
    }
    @media (max-width: 991px) {
        .box-nav-footer {
            width: 100%;
            text-align: center;
            .nav-footer {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                li {
                    width: 100%;
                    margin-bottom: 5px;
                    min-width: 190px;
                    a {
                        font-size: 16px;
                    }
                }
            }
        }
        .company-info {
            margin-bottom: 30px;
        }
    }
    @media (max-width: 767px) {
        .box-logo {
            padding-bottom: 20px;
            margin-bottom: 20px;
            img {
                max-width: 300px;
            }
        }
        .company-info {
            padding: 15px;
        }
        .maps-social {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 20px;
            .title {
                margin-top: 0 !important;
            }
            .map {
                max-width: 300px;
            }
            .social {
                flex: 1 1 auto;
                padding-left: 15px;
            }
        }

        .box-nav-footer {
            border-bottom: 3px solid rgba(255, 255, 255, 0.2);
            border-top: 3px solid rgba(255, 255, 255, 0.2);
            padding-top: 20px;
            text-align: left;
            padding: 15px 30px;
            .nav-footer {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                li {
                    width: 100%;
                    margin-bottom: 5px;
                    min-width: 190px;
                    a {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
</style>
