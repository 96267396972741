/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
const axios = require('../axios.config').default;
const blog = require('../axios2.config').default;

export default {
    namespaced: true,
    state: () => ({
        banner: [],
        customer: [],
        press: [],
        question: [],
    }),

    getters: {
        Banner: (state) => state.banner,
        Customer: (state) => state.customer,
        Press: (state) => state.press,
        Question: (state) => state.question,
    },

    actions: {
        get_Banner: ({ commit }) => {
            axios.get('banner').then((res) => {
                commit('SET_BANNER', res);
            });
        },
        get_Customer: ({ commit }) => {
            blog.get('customer').then((res) => {
                commit('SET_CUSTOMER', res);
            });
        },
        get_Press: ({ commit }) => {
            blog.get('newspaper').then((res) => {
                commit('SET_PRESS', res);
            });
        },
        get_Question: ({ commit }) => {
            blog.get('question').then((res) => {
                commit('SET_QUESTION', res);
            });
        },
    },

    mutations: {
        SET_BANNER: (state, data) => {
            state.banner = data;
        },
        SET_CUSTOMER: (state, data) => {
            state.customer = data;
        },
        SET_PRESS: (state, data) => {
            state.press = data;
        },
        SET_QUESTION: (state, data) => {
            state.question = data;
        },
    },
};
