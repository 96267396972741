<template>
    <router-view></router-view>
</template>
<script>
import { mapMutations } from 'vuex';

export default {
    components: {},
    data() {
        return {
            s500: false,
        };
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
    },
    mounted() {
        this.$tawkMessenger.$on('load', () => {
            // this.$tawkMessenger.toggle();
        });
    },
    created() {
        this.unsubscribe = this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'core/SUCCESS_MESSAGE':
                    this.outLoad();
                    this.$toastr.s(
                        this.$store.state.core.success,
                        'Successfully',
                    );

                    break;
                case 'core/ERROR_MESSAGE':
                    this.outLoad();
                    this.$toastr.e(this.$store.state.core.error, 'Oop!');

                    break;
                case 'core/ERROR_500':
                    this.outLoad();
                    this.s500 = true;

                    break;

                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>
<style>
body {
    padding: 0px !important;
}
</style>
