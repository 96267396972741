<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            menu: true,
            windowWidth: 1000,
            positionScroll: 0,
            nameRoute: null,
            currentLanguage: window.$cookies.get('language') || 'vi',
            languageName: ['vi', 'en'],
            showLang: false,
        };
    },
    computed: {
        ...mapGetters({
            LinkDownload: 'core/LinkDownload'
        }),
        menuList() {
            return [
                {
                    id: 1,
                    name: 'Home',
                    label: this.$t('home'),
                },
                {
                    id: 2,
                    name: 'AboutUs',
                    label: this.$t('aboutUs'),
                    children: [
                        {
                            id: 12,
                            name: 'About',
                            label: this.$t('productsAndServices'),
                        },
                        {
                            id: 13,
                            name: 'Company',
                            label: this.$t('organization'),
                        },
                        {
                            id: 14,
                            name: 'Cooperate',
                            label: this.$t('cooperationWithPIF'),
                        },
                    ],
                },
                {
                    id: 3,
                    name: 'Products',
                    label: this.$t('productsAndServices'),
                    children: [
                        {
                            id: 10,
                            name: 'Representative',
                            label: this.$t('typicalProducts'),
                        },
                        {
                            id: 11,
                            name: 'Service',
                            label: this.$t('serviceProvided'),
                        },
                    ],
                },
                {
                    id: 5,
                    label: this.$t('project'),
                    name: 'Projects',
                    children: [
                        {
                            id: 9,
                            name: 'Investing',
                            label: this.$t('projectIsCallingForCapital'),
                        },
                    ],
                },
                {
                    id: 6,
                    name: 'News',
                    label: this.$t('news'),
                },
                {
                    id: 7,
                    name: 'Events',
                    label: this.$t('events'),
                },
                {
                    id: 8,
                    name: 'Contacts',
                    label: this.$t('contact'),
                },
                {
                    id: 15,
                    name: this.$t('downloadApp'),
                    label: 'App',
                    children: [
                        {
                            id: 16,
                            name: 'android',
                            label: 'Android',
                        },
                        {
                            id: 17,
                            name: 'ios',
                            label: 'Ios',
                        },
                    ],
                },
            ];
        },
    },
    methods: {
        onWindowResize() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth <= 1400) {
                this.menu = false;
            } else {
                this.menu = true;
            }
        },
        handleScroll() {
            this.positionScroll = window.scrollY;
        },
        onDownloadApp(label) {
            if (label === 'Ios') {
                const link = document.createElement('a');
                link.id = 'abc';
                link.href = this.LinkDownload.ios;
                link.target = '_blank';
                link.click();
                return;
            }
            if (label === 'Android') {
                const link = document.createElement('a');
                link.id = 'abc';
                link.href = this.LinkDownload.url;
                link.target = '_blank';
                link.click();
            }
        },
        changeLanguage(lang) {
            this.currentLanguage = lang;
            this.$i18n.locale = lang;
            window.$cookies.set('language', lang);
            this.$store.dispatch('changeLanguage', lang);
            window.location.reload();
        },
    },
    watch: {
        $route: {
            handler() {
                this.nameRoute = this.$route.name;
                if (this.windowWidth <= 1400) {
                    this.menu = false;
                }
            },
        },
    },
    mounted() {
        this.onWindowResize();
        this.nameRoute = this.$route.name;
    },
    created() {
        this.$store.dispatch('core/req_getLinkDownload');
        window.addEventListener('resize', this.onWindowResize);
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('resize', this.onWindowResize);
        window.removeEventListener('scroll', this.handleScroll);
    },
};
</script>
<template>
    <b-container fluid class="header" :class="`${nameRoute} ${positionScroll >= 80 ? 'sticky-header' : ''}`">
        <b-container fluid class="nav-header">
            <div class="nav-brand">
                <img src="~@/assets/images/logo/icon-dark.png" alt="" />
            </div>
            <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeInRight"
                leave-active-class="animate__animated animate__fadeOutRight">
                <div class="nav-menu" v-if="menu">
                    <template v-for="menu in menuList">
                        <a href="javascript: void(0);" v-if="!menu.name || menu.children" :key="`menu-drop-${menu.id}`"
                            class="menu-item drop">
                            <b-dropdown v-if="menu.label !== 'App'">
                                <template #button-content>
                                    {{ menu.label }}
                                </template>
                                <b-dropdown-item v-for="children in menu.children" :key="`children-${children.id}`"
                                    :to="{ name: children.name }">
                                    {{ children.label }}
                                </b-dropdown-item>
                            </b-dropdown>
                            <b-dropdown v-else>
                                <template #button-content>
                                    {{ menu.name }}
                                </template>
                                <b-dropdown-item id="app" v-for="children in menu.children"
                                    :key="`children-${children.id}`" @click="onDownloadApp(children.label)">
                                    {{ children.label }}
                                </b-dropdown-item>
                            </b-dropdown>
                        </a>
                        <router-link v-else :key="`menu-main-${menu.id}`" :to="{ name: menu.name }" class="menu-item">
                            {{ menu.label }}
                        </router-link>
                    </template>
                </div>
            </transition>
            <div class="nav-navigation-menu">
                <a href="https://system.pifund.io/" target="_blank">
                    {{ $t('invest') }}
                </a>
                <div class="lang" @click="showLang = !showLang">
                    <div class="lang-left">
                        <img :src="
                            require(`@/assets/images/lang/${currentLanguage}.png`)
                        " alt="" />{{ currentLanguage }}
                    </div>
                    <div class="lang-right">
                        <img src="~@/assets/images/icons/chevron-down.png" alt="" width="30px" height="auto" />
                    </div>
                    <div class="lang-list" v-if="showLang">
                        <div class="lang-item" v-for="lang in languageName" :key="lang" @click="changeLanguage(lang)">
                            <img :src="
                                require(`@/assets/images/lang/${lang}.png`)
                            " alt="" width="30px" height="auto" />{{ lang }}
                        </div>
                    </div>
                </div>
                <div class="mobile-menu" v-if="windowWidth <= 1400">
                    <a class="menu-toggle" @click="menu = !menu">
                        <img src="~@/assets/images/icons/menu.png" v-if="!menu" alt="" />
                        <img src="~@/assets/images/icons/close.png" v-else alt="" />
                    </a>
                </div>
            </div>
        </b-container>
        <div class="backdrop" v-if="windowWidth <= 1400 && menu" @click="menu = !menu"></div>
    </b-container>
</template>
<style lang="scss">
.header {
    background-color: rgba($color: #000093, $alpha: 1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    z-index: 500;
    transition: all 0.2s;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;

    @media (min-width: 1400px) {
        .container {
            max-width: 1320px;
        }
    }

    &.Home {
        background-color: rgba($color: #000093, $alpha: 0.7);
    }

    &:not(.Home) {
        position: relative;
    }

    &.sticky-header {
        height: 80px;
        background-color: rgba($color: #000093, $alpha: 1);
        box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.4);
        position: fixed !important;

        .nav-header {
            height: 80px;
        }

        @media (max-width: 767px) {
            height: 60px;

            .nav-header {
                height: 60px;
            }
        }
    }

    .backdrop {
        z-index: 490;
        transition: all 0.2s;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background-color: rgba($color: #000000, $alpha: 0.4);
    }

    .nav-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
        position: relative;
        z-index: 500;

        .nav-brand {
            flex: 0 0 max-content;
            height: 100%;
            padding: 10px 0;

            img {
                height: 100%;
                width: auto;
                filter: drop-shadow(0px 0px 1px #000);
            }
        }

        .nav-menu {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1 1 auto;
            height: 100%;

            .menu-item {
                flex: 1;
                max-width: max-content;
                margin: 0 10px;
                height: 90%;
                display: flex;
                align-items: center;
                position: relative;
                transition: all cubic-bezier(0.215, 0.61, 0.355, 1);
                color: #fff;
                font-weight: 300;
                text-decoration: none;
                font-size: 19px;
                letter-spacing: 0.7px;

                @media (max-width: 768px) {
                    flex: 0 0 70px;
                }

                &.active,
                &:hover {
                    transition: all 1s;

                    &:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 10%;
                        width: 100%;
                        height: 3px;
                        background-color: #fff;
                        border-radius: 5px;
                        box-shadow: 1px 0px 5px 2px #00000040;
                    }

                    font-weight: 600;

                    // text-shadow: 1px 3px 1px #000000;
                    button {
                        font-weight: 600;
                    }
                }
            }

            button {
                background: none !important;
                border: none;
                padding: 0;
                outline: none !important;
                box-shadow: none !important;
                flex: 1;
                max-width: initial;
                margin: 0 10px;
                height: 90%;
                display: flex;
                align-items: center;
                transition: all cubic-bezier(0.215, 0.61, 0.355, 1);
                color: #fff;
                font-weight: 300;
                text-decoration: none;
                font-size: 19px;
                letter-spacing: 0.7px;
                width: 100%;

                &:hover {
                    &:after {
                        display: inline-block;
                        margin-left: 0.255em;
                        vertical-align: 0.255em;
                        content: '';
                        border-top: 0.3em solid;
                        border-right: 0.3em solid transparent;
                        border-bottom: 0;
                        border-left: 0.3em solid transparent;
                        width: auto;
                        height: auto;
                        position: unset;
                        background-color: transparent;
                    }
                }
            }

            .btn-group {
                @media (max-width: 11400px) {
                    width: 100%;
                    justify-content: center;
                }

                .dropdown-menu {
                    background-color: rgba($color: #02025e, $alpha: 0.9);
                    padding: 0;
                    top: 100% !important;
                    border-radius: 5px;
                    outline: none !important;
                    box-shadow: 2px 3px 5px 2px rgba($color: #000000, $alpha: 0.2);
                    width: 250px;

                    @media (max-width: 1400px) {
                        top: 0% !important;
                        left: 5px !important;
                    }

                    li {
                        &:first-child {
                            .dropdown-item {
                                border-top-left-radius: 5px;
                                border-top-right-radius: 5px;
                            }
                        }

                        &:last-child {
                            .dropdown-item {
                                border-bottom-left-radius: 5px;
                                border-bottom-right-radius: 5px;
                                border-bottom: 0;
                            }
                        }

                        .dropdown-item {
                            text-shadow: none;
                            font-weight: 600;
                            color: #fff;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            border-bottom: 3px solid #fff;

                            &:hover,
                            &.active {
                                background-color: rgba($color: #3333f7,
                                        $alpha: 1);
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }

        .nav-navigation-menu {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex: 0 0 max-content;
            margin-left: 15px;

            a {
                background: linear-gradient(172deg, #237fff 0%, #0930ff 100%);
                border: 2px solid #fff;
                text-decoration: none;
                transition: all 0.5s;
                height: 35px;
                width: 130px;
                border-radius: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all cubic-bezier(0.215, 0.61, 0.355, 1);
                color: #fff;
                font-weight: 600;
                text-decoration: none;
                font-size: 18px;
                letter-spacing: 0.7px;
                text-transform: uppercase;
                box-shadow: 1px 2px 10px 2px rgba($color: #000000, $alpha: 0.3);

                &:hover {
                    transform: scale(1.05);
                }
            }

            .lang {
                margin-left: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: linear-gradient(172deg, #237fff 0%, #0930ff 100%);
                border: 2px solid #fff;
                height: 35px;
                width: 110px;
                border-radius: 25px;
                color: #fff;
                font-weight: bold;
                padding: 0px 10px;
                cursor: pointer;
                position: relative;

                @media (max-width: 414px) {
                    height: 35px;
                    width: 80px;
                    font-size: 14px;
                    margin-left: 10px;
                }

                &:hover {
                    transform: scale(1.05);
                }

                .lang-left {
                    display: flex;
                    text-transform: uppercase;
                    align-items: center;

                    img {
                        margin-right: 5px;
                        height: 20px;
                        width: 30px;

                        @media (max-width: 414px) {
                            height: 15px;
                            width: 25px;
                        }
                    }
                }

                .lang-right {
                    img {
                        height: 20px;
                        width: auto;

                        @media (max-width: 414px) {
                            height: 15px;
                        }
                    }
                }

                .lang-list {
                    position: absolute;
                    top: 40px;
                    left: 0;
                    height: 65px;
                    width: 110px;
                    background: linear-gradient(172deg,
                            #237fff 0%,
                            #0930ff 100%);
                    border: 2px solid #fff;
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;

                    .lang-item {
                        display: flex;
                        align-items: center;
                        padding-left: 20px;
                        text-transform: uppercase;

                        &:nth-child(1) {
                            margin-bottom: 5px;
                        }

                        img {
                            margin-right: 5px;
                        }

                        &:hover {
                            background: yellow;
                            color: #0930ff;
                            border-radius: 8px;
                        }
                    }
                }
            }

            .menu-toggle {
                background-color: #0086ff;
                height: 35px;
                width: 45px;
                border-radius: 5px;
                padding: 5px;
                margin-left: 10px;

                img {
                    width: auto;
                    height: 100%;
                    filter: drop-shadow(1px 1px 1px #ccc);
                }
            }
        }

        @media (max-width: 1439px) {
            .nav-menu {

                button,
                .menu-item {
                    display: flex;
                    justify-content: center;
                    font-size: 16px !important;
                }
            }
        }

        @media (max-width: 1199px) {
            .nav-brand {
                flex: 0 0 max-content;
                margin-right: 20px;

                @media (max-width: 414px) {
                    margin-right: 10px;
                }
            }

            .nav-menu {

                button,
                .menu-item {
                    font-size: 15px !important;
                }
            }

            .nav-navigation-menu {
                a {
                    width: 100px;
                    height: 30px;

                    @media (max-width: 414px) {
                        height: 35px;
                        width: 80px;
                        font-size: 14px;
                    }
                }
            }
        }

        @media (max-width: 991px) {
            max-width: 100%;
            padding: 0;
        }

        @media (max-width: 991px) and (min-width: 768px) {
            .nav-brand {
                flex: 0 0 50px;
                display: flex;
                align-items: center;
                margin-right: 10px;

                img {
                    height: auto;
                    width: 100%;
                }
            }

            .nav-menu {

                button,
                .menu-item {
                    text-align: center;
                    font-size: 13px !important;
                    margin: 0 15px 0 0 !important;
                }
            }

            .nav-navigation-menu {
                margin-left: 10px;

                a {
                    background-color: #0086ff;
                    height: 35px;
                    width: 80px;
                    border-radius: 25px;
                    font-size: 14px;
                }
            }
        }

        @media (max-width: 1400px) {
            height: 60px;

            .nav-menu {
                position: fixed;
                top: 60px;
                flex-wrap: wrap;
                flex-direction: column;
                width: 280px;
                background-color: rgba($color: #000093, $alpha: 1);
                right: 0;
                height: 100%;
                z-index: 500;
                justify-content: flex-start;
                box-shadow: 2px 10px 10px 5px rgba(0, 0, 0, 0.4);

                .menu-item {
                    width: 100%;
                    height: 60px;
                    max-width: unset;
                    text-align: center;
                    justify-content: center;
                    margin: 0;
                    flex: unset;

                    &:after {
                        bottom: 0;
                    }
                }
            }
        }
    }
}
</style>
