const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        error: '',
        success: '',
        type_account: [],
        notifileList: [],
        maintainers: 0,
        linkDownload: [],
    }),

    getters: {
        Error: (state) => state.error,
        Success: (state) => state.success,
        typeAccount: (state) => state.type_account,
        NotifileList: (state) => state.notifileList,
        Maintainers: (state) => state.maintainers,
        LinkDownload: (state) => state.linkDownload,
    },

    actions: {
        req_getNotificationImage({ commit }) {
            axios.get('api/v1/notification-image').then((res) => {
                if (res) {
                    commit('SET_NOTIFICATION_IMAGE', res);
                }
            });
        },
        req_getSettings() {
            axios.get('api/v1/setting-main').then((res) => {
                if (res) {
                    // commit('SET_SETTING', res);
                }
            });
        },
        req_getLinkDownload({ commit }) {
            axios.get('web/app-apk').then((res) => {
                if (res) {
                    commit('SET_LINK_DOWNLOAD', res);
                }
            });
        },
    },
    mutations: {
        SUCCESS_MESSAGE(state, message) {
            state.success = message;
        },
        ERROR_MESSAGE(state, message) {
            state.error = message;
        },
        ERROR_500(state, message) {
            state.status = message;
        },
        SET_NOTIFICATION_IMAGE(state, data) {
            state.notifileList = data.system;
        },
        SET_LINK_DOWNLOAD(state, data) {
            state.linkDownload = data;
        },
    },
};
