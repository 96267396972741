/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
const axios = require('../axios.config').default;
const blog = require('../axios2.config').default;

export default {
    namespaced: true,
    state: () => ({
        list_event: {
            data: [],
            current: 1,
            total: 1,
        },
    }),

    getters: {
        ListEvent: (state) => state.list_event,
    },

    actions: {
        get_AllEvent: ({ commit }, input) => {
            blog.get('event', { params: input }).then((article) => {
                commit('SET_LIST_EVENT', article);
            });
        },
    },

    mutations: {
        SET_LIST_EVENT: (state, data) => {
            state.list_event = {
                current: data.current_page,
                data: data.data,
                total: data.last_page,
            };
        },
    },
};
