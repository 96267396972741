/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
const axios = require('../axios.config').default;
const blog = require('../axios2.config').default;

export default {
    namespaced: true,
    state: () => ({
        list_service: {},
        list_project: {},
        list_province: {},
        list_service_rePresentatve: {},
        detail_project: {},
        financial_report: {},
        electronic_contract: {},
        typical_product: {},
        typical_product_address: {}
    }),

    getters: {
        ListService: (state) => state.list_service,
        ListProject: (state) => state.list_project,
        ListProvince: (state) => state.list_province,
        ListServiceRePresentatve: (state) => state.list_service_rePresentatve,
        DetailProject: (state) => state.detail_project,
        FinancialReport: (state) => state.financial_report,
        ElectronicContract: (state) => state.electronic_contract,
        TypicalProduct: (state) => state.typical_product,
        TypicalProductAddress: (state) => state.typical_product_address,
    },

    actions: {
        get_listService: ({ commit }) => {
            axios.get('service').then((article) => {
                commit('SET_LIST_SERVICE', article);
            });
        },
        get_listProject: ({ commit }, input) => {
            axios.post('web/list-project', input).then((article) => {
                commit('SET_LIST_PROJECT', article);
            });
        },
        get_listProvince: ({ commit }) => {
            axios.get('province').then((article) => {
                commit('SET_LIST_PROVINCE', article);
            });
        },
        get_listProjectRepresentative: ({ commit }, input) => {
            axios.post('web/list-project-typical', input).then((article) => {
                commit('SET_LIST_PROJECT_REPRESENTATIVE', article);
            });
        },
        get_detailsProject: ({ commit }, input) => {
            axios.get(`web/project-detail/${input}`).then((article) => {
                commit('SET_DETAIL_PROJECT', article);
            });
        },
        get_financialReport: ({ commit }, input) => {
            axios.get('web/finance-report', { params: { project_id: input } }).then((article) => {
                commit('SET_FINANCIAL_REPORT', article);
            });
        },
        get_electronicContract: ({ commit }, input) => {
            axios.get('electronic-contract', { params: { project_id: input } }).then((article) => {
                commit('SET_ELECTRONIC_CONTRACT', article);
            });
        },
        get_typicalProduct: ({ commit }, input) => {
            blog.get('product/address', { params: { id: input } }).then((article) => {
                commit('SET_TYPICAL_PRODUCT', article);
            });
        },
        get_typicalProductAddress: ({ commit }) => {
            blog.get('address').then((article) => {
                commit('SET_TYPICAL_PRODUCT_ADDRESS', article);
            });
        },
    },

    mutations: {
        SET_LIST_SERVICE: (state, data) => {
            state.list_service = data
        },
        SET_LIST_PROJECT: (state, data) => {
            state.list_project = data
        },
        SET_LIST_PROVINCE: (state, data) => {
            state.list_province = data
        },
        SET_LIST_PROJECT_REPRESENTATIVE: (state, data) => {
            state.list_service_rePresentatve = data
        },
        SET_DETAIL_PROJECT: (state, data) => {
            state.detail_project = data
        },
        SET_FINANCIAL_REPORT: (state, data) => {
            state.financial_report = data
        },
        SET_ELECTRONIC_CONTRACT: (state, data) => {
            state.electronic_contract = data
        },
        SET_TYPICAL_PRODUCT: (state, data) => {
            state.typical_product = data
        },
        SET_TYPICAL_PRODUCT_ADDRESS: (state, data) => {
            state.typical_product_address = data
        },
    },
};
