import GlobalLayout from './layouts/Main.vue';

export default [
    {
        path: '/',
        component: GlobalLayout,
        redirect: { name: 'Home' },
        children: [
            {
                path: 'trang-chu',
                name: 'Home',
                component: () => import('./views/Home.vue'),
            },
            {
                path: 'tin-tuc',
                name: 'News',
                component: () => import('./views/News.vue'),
            },
            {
                path: 'su-kien',
                name: 'Events',
                component: () => import('./views/Event.vue'),
            },
            {
                path: 'lien-he',
                name: 'Contacts',
                component: () => import('./views/Contact.vue'),
            },
            {
                path: 'tin-tuc/:slug',
                name: 'NewsDetail',
                component: () => import('@/components/Blog/DetailsNews.vue'),
            },
            {
                path: 'su-kien/:slug',
                name: 'EventDetail',
                component: () => import('@/components/Blog/DetailsEvent.vue'),
            },
            {
                path: 'chi-tiet-du-an/:id',
                name: 'ProjectDetail',
                component: () =>
                    import('@/components/Projects/ProjectDetail.vue'),
            },
            {
                path: 'policy',
                name: 'Policy',
                component: () =>
                    import('./views/Policy.vue'),
            },
        ],
    },
    {
        path: '/ve-chung-toi',
        name: 'AboutUs',
        component: GlobalLayout,
        redirect: { name: 'About' },
        children: [
            {
                path: 'quy-dau-tu-pi-investment',
                name: 'Company',
                component: () => import('@/components/Abouts/Company.vue'),
            },
            {
                path: 'hop-tac-voi-pi-investment',
                name: 'Cooperate',
                component: () => import('@/components/Abouts/Cooperate.vue'),
            },
            {
                path: 'chung-toi-la-ai',
                name: 'About',
                component: () => import('@/components/Abouts/Abouts.vue'),
            },
        ],
    },
    {
        path: '/du-an',
        name: 'Projects',
        component: GlobalLayout,
        redirect: { name: 'Investing' },
        children: [
            {
                path: 'dang-goi-von',
                name: 'Investing',
                component: () => import('@/components/Projects/Investing.vue'),
            },
            {
                path: 'tieu-bieu',
                name: 'Representative',
                component: () =>
                    import('@/components/Projects/Representative.vue'),
            },
        ],
    },
    {
        path: '/san-pham&dich-vu',
        name: 'Products',
        component: GlobalLayout,
        redirect: { name: 'Service' },
        children: [
            {
                path: 'dich-vu',
                name: 'Service',
                component: () => import('./views/Products.vue'),
            },
            {
                path: 'tieu-bieu',
                name: 'Representative',
                component: () =>
                    import('@/components/Projects/Representative.vue'),
            },
        ],
    },
];
