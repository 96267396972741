import Vue from 'vue';
import store from '@/store';
import { BootstrapVue } from 'bootstrap-vue';
import VueClipboard from 'vue-clipboard2';
import VueToastr from 'vue-toastr';
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue';
import moment from 'moment';
import AOS from 'aos';
import App from './App.vue';
import router from './router';
import './design/scss/app.scss';
import 'aos/dist/aos.css';

// import fontawesome
import '@/assets/font-awesome/css/duotone.min.css';
import '@/assets/font-awesome/css/fontawesome.min.css';
import '@/assets/font-awesome/css/solid.min.css';
import '@/assets/font-awesome/css/regular.min.css';

import '@/assets/font-awesome/js/fontawesome';
import '@/assets/font-awesome/js/solid';
import '@/assets/font-awesome/js/duotone';
import '@/assets/font-awesome/js/regular';
import i18n from './i18n';

Vue.use(VueClipboard);
VueClipboard.config.autoSetContainer = true;

Vue.use(BootstrapVue);

Vue.use(TawkMessengerVue, {
  propertyId : '6243be810bfe3f4a87706195',
  widgetId : '1fvca3es3'
});

Vue.use(VueToastr, {
    defaultTimeout: 3000,
    defaultPosition: 'toast-top-right',
    defaultClassNames: ['animate__animated ', 'animate__bounceInRight'],
});

Vue.mixin({
    methods: {
        getDateTime(unix) {
            moment.locale('vi');
            return moment(unix).utcOffset('+00:00').format('DD/MM/YYYY');
        },
    },
});

Vue.config.productionTip = false;

new Vue({
    router,
    created() {
        AOS.init({
            // Global settings:
            disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
            startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
            initClassName: 'aos-init', // class applied after initialization
            animatedClassName: 'aos-animate', // class applied on animation
            useClassNames: true, // if true, will add content of `data-aos` as classes on scroll
            disableMutationObserver: false, // disables automatic mutations' detections (advanced)
            debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
            throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

            // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
            offset: 100, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 2500, // values from 0 to 3000, with step 50ms
            easing: 'ease', // default easing for AOS animations
            once: false, // whether animation should happen only once - while scrolling down
            mirror: false, // whether elements should animate out while scrolling past them
            anchorPlacement: 'top-bottom', // defines which position of the element regardi
        });
    },
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
