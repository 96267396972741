
const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
    }),

    getters: {
    },

    actions: {
        req_postSendContract: ({ commit }, input) => {
            axios.post('web/send-mail-contact', input).then((res) => {
                commit('SEND_CONTACT_SUCCESS', res);
            });
        },
    },

    mutations: {
        SEND_CONTACT_SUCCESS: () => {
        },
    },
};
