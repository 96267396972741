/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
const axios = require('../axios.config').default;
const blog = require('../axios2.config').default;

export default {
    namespaced: true,
    state: () => ({
        most_news: [],
        list_news: {
            data: [],
            current: 1,
            total: 1,
        },
        category: [],
    }),

    getters: {
        MostNews: (state) => state.most_news,
        ListNews: (state) => state.list_news,
        Category: (state) => state.category,
    },

    actions: {
        get_MostNews: ({ commit }) => {
            blog.get('article/highlight').then((article) => {
                commit('SET_MOST_NEWS', article);
            });
        },
        get_AllNews: ({ commit }, input) => {
            blog.get('article', { params: input }).then((article) => {
                commit('SET_LIST_NEWS', article);
            });
        },
        get_NewsOfCategory: ({ commit }, input) => {
            blog.get('article/blog', { params: input }).then((article) => {
                commit('SET_LIST_NEWS', article);
            });
        },
        get_Category: ({ commit }) => {
            blog.get('blog').then((blog) => {
                commit('SET_CATEGORY', blog);
            });
        },
    },

    mutations: {
        SET_MOST_NEWS: (state, data) => {
            state.most_news = data.data;
        },
        SET_LIST_NEWS: (state, data) => {
            state.list_news = {
                current: data.current_page,
                data: data.data,
                total: data.last_page,
            };
        },
        SET_CATEGORY: (state, data) => {
            state.category = data.data;
        },
    },
};
